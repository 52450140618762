.positionFixed {
  background: #071942;
}

.borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

  margin: 0 18px !important;
}
.subMenu {
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e9eef6;
  padding: 10px;
  //box-shadow: 0 4px 8px 3px rgba(0, 0, 0, .15), 0 1px 3px rgba(0, 0, 0, .3);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .subGrid {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
   background-color: #fff;
   margin:0;
   padding: 10px 15px 10px 10px;
  }
  svg {
    width:30px;
    height:30px;
    display: block;
    margin: 0 auto 5px auto;
    padding-top: 5px;
  }
  .centerFlex{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100px;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    height:100px;
    &:hover{
      overflow: visible;
      white-space:normal;
      background-color:#f1f7ff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 14px;
    }
  }
}
.aibotClose {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  cursor: pointer;
}

.notificationClose {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.headerWrap {
  display: flex;
  justify-content: space-between;
  background: #071942;

  .headerLeftwrap {
    display: flex;

    .menuDots {
      display: flex;
      margin-right: 10px;
      background: transparent;

      button {
        padding: 0;
        min-width: 30px;
        background: transparent;
      }

     
    }

    .MuiSnackbarContent-root {
      background-color: #fff;
    }
  }

  .headerRight {
    display: flex;
    align-items: center;

    svg {
      display: flex;
      margin-right: 8px;
      cursor: pointer;
      margin-left: 0;
    }
  }
}

.menuWrap {
  .MuiDrawer-paper {
    z-index: 1199 !important;
  }

  background-color: transparent !important;

  .titleWrap {
    display: flex;
  }

  .settingWrap {
    display: flex;

    .imageWrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .textWrap {
      display: flex;
      flex-direction: column;

      p {
        font-size: 15px;
        color: #071942;
        margin: 0;
        font-weight: 600;
      }

      span {
        color: #071942;
        opacity: 60%;
        font-size: 13px;
      }
    }
  }

  &:hover {
    background-color: transparent !important;
  }
}

.listItemwrap {
  background-color: #fff;

  .MuiPaper-elevation {
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .MuiAlert-icon {
      display: none;
    }

    .MuiAlert-action {
      position: absolute;
      right: 10px;
    }
  }

  .listItem {
    padding: 0;

    div {
      padding: 3px 5px;
      min-width: inherit;
    }

    .MuiListItemText-primary {
      //font-size: 14px;
      font-size: 0.875rem;
      color: rgba(7, 25, 66, 0.5);
    }
  }
}

.outerDrawer {
  position: relative;

  .arrows {
    position: absolute;
    right: -11px;
    z-index: 1200;
    bottom: 80px;
    border-radius: 50%;
    border: 1px solid #999;
    display: flex;
    cursor: pointer;
    background-color: #fff;
  }

  .MuiListItemButton-root {
    margin: 6px !important;
    border-radius: 6px;
    padding: 0.25rem 0.9rem;
    font-weight: 600 !important;
    min-height: 40px;
  }

  .MuiTypography-root {
    font-size: 0.875rem;
    white-space: nowrap;
    width: calc(100% - 0px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    display: inline-block;
  }

  .MuiListItemText-root {
    margin-left: -8px;
  }
}

mat-card-header.mat-card-header.ng-star-inserted {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 18px;

  .mat-card-avatar.header-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .mat-card-header-text {
    padding: 10px 14px;
    display: flex;
    flex-direction: column;

    .username {
      display: block;
      font-weight: 500;
      font-size: 0.938rem;
    }

    .orgname {
      font-size: 0.813rem;
    }
  }
}