

tr{
    height: 48px;

}

tr:first-child{
    height: 42px;
}

.MuiTableRow-root{
    background-color: #fff;
    td{
        color:#071942;
        font-size: 14px;
    }
    th{
        color:#071942;
      //  background-color: rgba(7, 25, 66, 0.12);
        background: #F5F5F5;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
    }
   

   
}

.MuiTableRow-root td {
    background-color: #fff !important;
}

// th{

// hr{
//     background-color: red;
// }
// hr{
// &:hover{
//     background-color: green;
// }

// }
// }

.Mui-TableHeadCell-ResizeHandle-Wrapper.MuiBox-root{
   opacity: 0;
   &:hover{
opacity: 1;
   }
   
}

.MuiSvgIcon-root.MuiSelect-icon{
    top:-3px;
}

.MuiFormLabel-root.MuiInputLabel-root{
    top:-2px
}


