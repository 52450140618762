.homeWrap {
    .flexCenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiContainer-maxWidthXl {
        padding: 0 0 0 12px;
        max-width: 100% !important;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;
                margin-top: 0px;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                font-family: Rubik;
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .cardGrid {
  
        display: flex;
        flex-direction: row;
        width: 20%;
        padding: 0 6px;
        margin-top: 10px;

        .highlightWrap {
            width: 100%;
            padding: 8px;
                .highlightGray {
                    color: rgba(7, 25, 66, 0.5);
                    font-family: Rubik;
                    //font-size: 12px;
                    font-size: 0.80rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }

                .highlightRed {
                    color: #E25757;
                    font-family: Rubik;
                    //font-size: 12px;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.70rem;
                    }
                }

                .highlightTitle {
                    color: #071942;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 3px 0px 5px 0;
                    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.85rem;
                    }
                }

             
            

            hr {
                border-bottom: 1px solid #a29f9f1f;
            }

            .innerWrap {
                display: flex;
                padding: 10px 0;

                .highlightNum {
                    display: flex;
                    flex-direction: row;
                    color: #1A73E8;
                    //font-size: 20px;
                    font-size: 2rem;
                    font-style: normal;
                    margin-right: 10px;
                    font-weight: 600;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 1.5rem;
                    }
                }

                .highlightText {
                    display: flex;
                    flex-direction: row;
                    color: rgba(7, 25, 66, 0.50);
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    height: 32px;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }
            }

            .highlightFooter {
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                margin-top:5px;
                justify-content: space-between;
                .pgText {
                    border-radius: 6px;
                    background: rgba(26, 115, 232, 0.08);
                    font-size: 0.70rem;
                    padding: 2px 5px;
                    color: #1A73E8;
                    width:60px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    margin-right:8px;
                }
                .highOuterText {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
               

                .emailText {
                    width: 142px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 13px;
                }

                .timeText {
                    font-size: 12px;
                    color: rgba(7, 25, 66, 0.5);
                }
            }
        }
    }

    .imageWrap {
        img {
            width: 100vw;
        }
    }

    @media (min-width: 1920px) {
        .MuiContainer-root {
            // max-width: 1536px;
            max-width: 1900px !important;
            margin-left: 0 !important;
        }
    }
    
}