.mainFrame{
    width:100vw;
    height:100vh;
    .innerwrap {
        display:flex; flex-direction:column; align-items:center; justify-content:center;
        height:100vh;
        p{
            font-size:25px; font-weight:500;
        }
    }
}